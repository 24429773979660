import React, { useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import Suggested from '../components/Suggested'
import SEO from '../components/SEO'
import Comment from '../components/Comment'
import Blurb from '../components/Blurb'
import config from '../utils/config'
import { slugify } from '../utils/helpers'

export default function PostTemplate({ data, pageContext }) {
  const post = data.markdownRemark
  const { previous, next } = pageContext
  const { tags, thumbnail, title, description, date, updatedAt } =
    post.frontmatter
  const commentBox = React.createRef()

  useEffect(() => {
    const commentScript = document.createElement('script')
    const theme =
      typeof window !== 'undefined' && localStorage.getItem('theme') === 'dark'
        ? 'github-dark'
        : 'github-light'
    commentScript.async = true
    commentScript.src = 'https://utteranc.es/client.js'
    commentScript.setAttribute('repo', 'ubaidillahhf/comment')
    commentScript.setAttribute('issue-term', 'pathname')
    commentScript.setAttribute('id', 'utterances')
    commentScript.setAttribute('theme', theme)
    commentScript.setAttribute('crossorigin', 'anonymous')
    if (commentBox && commentBox.current) {
      commentBox.current.appendChild(commentScript)
    } else {
      console.log(`Error adding utterances comments on: ${commentBox}`)
    }
  }, []) // eslint-disable-line

  return (
    <Layout>
      <Helmet title={`${post.frontmatter.title} | ${config.siteTitle}`} />
      <SEO postPath={post.fields.slug} postNode={post} postSEO />

      <article>
        <header className="article-header">
          <div className="container">
            <div className="thumb">
              <div>
                <h1>{title}</h1>
                <div className="post-meta">
                  <div>
                    <b>👨‍💻</b> <Link to="/me">Ubaidillah Hakim Fadly</Link>.{' '}
                    <br />⌛ <b>{post.fields.readingTime.text}</b>
                    <br />
                    <b>
                      ✍🏻 Created <time>{date}</time> | Updated
                    </b>{' '}
                    <time>{updatedAt}</time>.
                  </div>
                  {tags && (
                    <div className="tags">
                      {tags.map((tag) => (
                        <Link
                          key={tag}
                          to={`/tags/${slugify(tag)}`}
                          className={`tag-${tag}`}
                        >
                          {tag}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {thumbnail && (
                <Img
                  fixed={thumbnail.childImageSharp.fixed}
                  className="post-thumbnail"
                />
              )}
            </div>
            {description && <p className="description">{description}</p>}
          </div>
        </header>
        <div className="container">
          <div
            className="article-post"
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </div>
      </article>
      <Blurb title="About the author">
        <p>
          Hey, I'm <Link to="/me">Ubaidillah Hakim Fadly</Link>, a software
          engineer, stock investor, and entrepreneur. I publish articles and
          tutorials about programming and devOps from my own research.
        </p>
        <p>
          <a
            className="button"
            href="https://ubed.substack.com"
            target="_blank"
            rel="noreferrer"
          >
            Get the newsletter
          </a>
          <a
            className="button"
            href="https://ko-fi.com/ubaidillahhf"
            target="_blank"
            rel="noreferrer"
          >
            Buy me a coffee
          </a>
        </p>
      </Blurb>
      <div className="container">
        <div id="comments">
          <h2>Comments</h2>
          <Comment commentBox={commentBox} />
        </div>

        <Suggested previous={previous} next={next} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      fields {
        slug
        readingTime {
          text
        }
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        updatedAt(formatString: "MMMM DD, YYYY")
        tags
        # description
        thumbnail {
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
